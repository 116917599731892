const swiper5 = new Swiper('.vacancies__carousel', {
  wrapperClass: 'vacancies__ribbon',
  slideClass: 'vacancies__card',
  direction: 'horizontal',
  loop: true,
  speed: 1000,
  spaceBetween: 15,
  autoplay: {
    delay: 4000,
  }
});